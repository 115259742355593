import styled from 'styled-components';

export const MainContainer = styled.div`
  flex: 1;
  min-height: 100%;
  display: flex;
  justify-content: center;
`;

export const MainContent = styled.div`
  flex: 1
  min-height: 100%;
  width: 100%;
`;
import React from "react"
import Sticky from "react-sticky-el"
import { IconContext } from "react-icons"
import { graphql } from "gatsby"

import {
  CustomerActionBar,
  GlobalStyle,
  IconProviderStyle,
  LayoutContainer,
  StickyContainer,
} from "./styles"

import Footer from "../footer"
import Main from "../main"
import Header from "../header"
import CustomerActions from "../header/customer-actions"

import CookieConsent from "react-cookie-consent"

const Layout = ({ children }) => {
  const title = graphql`
    query MyQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `

  return (
    <LayoutContainer>
      <GlobalStyle />
      <IconContext.Provider value={IconProviderStyle}>
        <StickyContainer desktop={true}>
          <Sticky>
            <Header />
          </Sticky>
        </StickyContainer>
        <Main children={children} title={title} />
        <StickyContainer desktop={true}>
          <Sticky mode="bottom" bottomOffset={80}>
            <CustomerActionBar>
              <CustomerActions color={"#03989E"} />
            </CustomerActionBar>
          </Sticky>
        </StickyContainer>
        <Footer />
      </IconContext.Provider>
      <CookieConsent
        location="bottom"
        buttonText="Alle akzeptieren"
        declineButtonText="Ablehnen"
        cookieName="gatsby-gdpr-google-analytics"
      >
        Wir nutzen Cookies um Dir die bestmögliche Browsing-Erfahrung zu bieten.
        Die mit Hilfe von Cookies gesammelten Daten werden zur Optimierung
        unserer Webseite genutzt und potenziellen Neukunden die für sie
        relevantesten Informationen anzuzeigen. Diese Daten werden im Rahmen
        unserer EU-weiten und globalen Tätigkeiten genutzt.
      </CookieConsent>
    </LayoutContainer>
  )
}

export default Layout

import React from "react"
import styled from "styled-components"
import { Link } from "gatsby-plugin-react-intl"

import { MdEmail } from "react-icons/md"
import { FaWhatsapp } from "react-icons/fa"
import { IoMdCall } from "react-icons/io"

const Content = styled.div`
  width: 300px;
  margin: 0 auto;
  font-size: 1.5rem;
  position: relative;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
`
const HeaderLink = styled(Link)`
  color: black;
  text-decoration: none;
  margin: 0.6rem 0;
`
const ALink = styled.a`
  text-decoration: none;
  color: black;
  margin: 0.6rem 0;
`

const HeaderModal = () => {
  return (
    <Content>
      <HeaderLink to="/kontakt">
        <MdEmail size={30} color="#f36c29" /> Email
      </HeaderLink>

      <ALink href="tel:+493085612349">
        <IoMdCall size={30} /> Telefon
      </ALink>

      <ALink href="https://wa.me/message/JQTQAPXYBLDNI1">
        <FaWhatsapp size={30} color="#2cb742" /> Whatsapp
      </ALink>
    </Content>
  )
}

export default HeaderModal

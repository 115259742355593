import { defineMessages } from "react-intl"

const scope = "landing"

export default defineMessages({
  cards: {
    titles: {
      notebook: {
        id: `${scope}.CardTitleNotebook`,
        defaultMessage: "Notebook-Reparatur",
      },
      macbook: {
        id: `${scope}.CardTitleMacbook`,
        defaultMessage: "MacBook-Reparatur",
      },
      board: {
        id: `${scope}.CardTitleBoard`,
        defaultMessage: "Mainboard-Reparatur",
      },
    },
    texts: {
      notebook: {
        id: `${scope}.CardTextNotebook`,
        defaultMessage:
          "Alle Hersteller und Modellreihen, faire Preisgestaltung.",
      },
      macbook: {
        id: `${scope}.CardTextNotebook`,
        defaultMessage:
          "Professionelle Logicboard-Reparaturen auf höchstem Niveau.",
      },
      board: {
        id: `${scope}.CardTextBoard`,
        defaultMessage:
          "Umfangreiche Diagnostik dank langjähriger Erfahrung im Industriebereich.",
      },
    },
  },
  buttons: {
    additionalInformation: {
      id: `${scope}.additionalInformation`,
      defaultMessage: "Weitere Informationen",
    },
  },
  shops: {
    headline: {
      id: `${scope}.ShopHeadline`,
      defaultMessage: "Zwei Filialen in Berlin",
    },
    inns: {
      title: {
        id: `${scope}.innsTitle`,
        defaultMessage: "Schöneberg",
      },
      desc: {
        id: `${scope}.innsDesc`,
        defaultMessage:
          "Unsere Werkstatt befindet sich direkt am Innsbrucker Platz. <break>Innerhalb unserer Öffnungszeiten ist es jederzeit möglich, Ihr Gerät abzugeben und auch wieder abzuholen.</break>",
      },
      termin: {
        id: `${scope}.innsTermin`,
        defaultMessage: "Ohne Termin",
      },
      phone: {
        id: `${scope}.innsPhone`,
        defaultMessage: "030 / 856 123 49",
      },
      openTime: {
        headline: {
          id: `${scope}.openTimeHeadline`,
          defaultMessage: "Öffnungszeiten",
        },
        day: {
          mon: {
            id: `${scope}.innsMon`,
            defaultMessage: "MO - DO",
          },
          fri: {
            id: `${scope}.innsFri`,
            defaultMessage: "FR.",
          },
          sat: {
            id: `${scope}.innsSat`,
            defaultMessage: "SA.",
          },
        },
        time: {
          mon: {
            id: `${scope}.innsMonTime`,
            defaultMessage: "10:00 - 18:00",
          },
          fri: {
            id: `${scope}.innsFriTime`,
            defaultMessage: "10:00 - 16:00",
          },
          sat: {
            id: `${scope}.innsSatTime`,
            defaultMessage: "Geschlossen",
          },
        },
      },
      direction: {
        title: {
          id: `${scope}.directionTitle`,
          defaultMessage: "Anfahrt",
        },
        info: {
          sbahn: {
            id: `${scope}.sbahnInfo`,
            defaultMessage: "Innsbrucker Platz",
          },
          ubahn: {
            id: `${scope}.ubahnInfo`,
            defaultMessage: "Innsbrucker Platz",
          },
          bus: {
            id: `${scope}.busInfo`,
            defaultMessage: "Innsbrucker Platz",
          },
        },
      },
      address: {
        id: `${scope}.innsAddress`,
        defaultMessage: "INNSBRUCKER PLATZ 4 , 10827 BERLIN",
      },
    },
    dams: {
      title: {
        id: `${scope}.damsTitle`,
        defaultMessage: "KU-Damm",
      },
      desc: {
        id: `${scope}.damsDesc`,
        defaultMessage:
          "Unsere Büros befinden sich direkt am Lehniner Platz. <break>Selbstverständlich ist es auch möglich, bestimmte Waren zur Reparatur direkt vor Ort am Empfang abzugeben. </break>",
      },
      termin: {
        id: `${scope}.damsTermin`,
        defaultMessage: "Nur mit Termin",
      },
      phone: {
        id: `${scope}.damsPhone`,
        defaultMessage: "030 / 355 282 12",
      },
      openTime: {
        headline: {
          id: `${scope}.openTimeHeadline`,
          defaultMessage: "Öffnungszeiten",
        },
        day: {
          mon: {
            id: `${scope}.damsMon`,
            defaultMessage: "MO - DO",
          },
          fri: {
            id: `${scope}.damsFri`,
            defaultMessage: "FR.",
          },
          sat: {
            id: `${scope}.damsSat`,
            defaultMessage: "SA.",
          },
        },
        time: {
          mon: {
            id: `${scope}damsMonTime`,
            defaultMessage: "10:00 - 18:00",
          },
          fri: {
            id: `${scope}.damsFriTime`,
            defaultMessage: "10:00 - 16:00",
          },
          sat: {
            id: `${scope}.damsSatTime`,
            defaultMessage: "Geschlossen",
          },
        },
      },
      direction: {
        title: {
          id: `${scope}.directionTitle`,
          defaultMessage: "Anfahrt",
        },
        info: {
          sbahn: {
            id: `${scope}.sbahnInfodams`,
            defaultMessage: "Halensee",
          },
          ubahn: {
            id: `${scope}.ubahnInfodams`,
            defaultMessage: "Adenauer Platz",
          },
          bus: {
            id: `${scope}.busInfodams`,
            defaultMessage: "Schaubühne",
          },
        },
      },
      address: {
        id: `${scope}.damsAddress`,
        defaultMessage: "DAMASCHKE STR. 2 , 10711 BERLIN",
      },
    },
  },
  ups: {
    upsHeadline: {
      id: `${scope}.UpsHeadline`,
      defaultMessage: "Expressversand <color>Spezial</color>",
    },
    upsSubHeadline: {
      id: `${scope}.UpsSubHeadline`,
      defaultMessage: "Hol- und Bringservice",
    },
    upsText: {
      id: `${scope}.UpsText`,
      defaultMessage:
        "Wir kümmern uns um die Logistik. <break>Wir übernehmen den Labeldruck für Sie – Ihnen entstehen keine zusätzlichen Kosten für den Druck einer Versandmarke.</break>",
    },
    upsPrice: {
      id: `${scope}.upsPrice`,
      defaultMessage: "ab 19,90 €",
    },
    repair: {
      id: `${scope}.repair`,
      defaultMessage: "Reparaturstatus <color>Live</color>",
    },
    repairText: {
      id: `${scope}.repairText`,
      defaultMessage:
        "Vom Wareneingang über die Fehleranalyse, Ersatzteilbestellung und Fertigstellung bis hin zum Rückversand. <break>Durch unser selbst entwickeltes Portal ist es Ihnen jederzeit möglich, selbst den Status Ihrer Reparatur zu überprüfen.</break>",
    },
  },
  aboutUs: {
    text: {
      textOne: {
        id: `${scope}.TextOne`,
        defaultMessage:
          "TMC-TEC ist eine registrierte Marke und Teil der SOLDA GmbH.",
      },
      textTwo: {
        id: `${scope}.TextTwo`,
        defaultMessage:
          " Der Kunde steht hier ganz klar im Vordergrund. Seit über 14 Jahren beschäftigen wir uns mit der professionellen Reparatur von elektronischen Geräten Aller Art wie Notebooks, Grafikkarten, Mainboards, Netzteilen sowie Systemen aus der Unterhaltungselektronik, Medizintechnik und der Industrie. ",
      },
      textThree: {
        id: `${scope}.TextThree`,
        defaultMessage:
          "Wir sind im Besitz von professionellem Equipment zum Löten von SMD, BGA, µBGA, CSP, und der FLIP CHIP Technologie. Die Reparaturen werden von erfahrenen Technikern mit einer großen Leidenschaft für Ihre Arbeit erledigt.",
      },
    },
    button: {
      id: `${scope}.AboutUsButton`,
      defaultMessage: "<click> Mehr über uns </click>",
    },
    title: {
      id: `${scope}.AboutUsTitle`,
      defaultMessage: "Wir bieten Ihnen zertifizierte Qualität!",
    },
    subtitle: {
      id: `${scope}.AboutUsSubtitle`,
      defaultMessage:
        "Unsere internationalen IPC -7711 / IPC-7721 Zertifikate geben Ihnen die Sicherheit, dass wir über das Wissen verfügen, Leiterplatten und elektronische Baugruppen fachgerecht zu modifizieren und zu reparieren.",
    },
    footer: {
      id: `${scope}.AboutUsFooter`,
      defaultMessage:
        "Diese Unternehmen profitierten bereits von unseren Dienstleistungen:",
    },
  },
  stats: {
    customer: {
      value: {
        id: `${scope}.StatCustomerValue`,
        defaultMessage: 23700,
      },
      text: {
        id: `${scope}.StatCustomerText`,
        defaultMessage: "Zufriedene Kunden",
      },
    },
    device: {
      value: {
        id: `${scope}.StatDeviceValue`,
        defaultMessage: 780,
      },
      text: {
        id: `${scope}.StatDeviceText`,
        defaultMessage: "Mon rep Geräte",
      },
    },
    partner: {
      value: {
        id: `${scope}.StatPartnerValue`,
        defaultMessage: 186,
      },
      text: {
        id: `${scope}.StatPartnerText`,
        defaultMessage: "Servicepartner",
      },
    },
    sucess: {
      value: {
        id: `${scope}.StatSucessValue`,
        defaultMessage: 91,
      },
      text: {
        id: `${scope}.StatSucessText`,
        defaultMessage: "Erfolgsquote",
      },
    },
    experience: {
      value: {
        id: `${scope}.StatExperienceValue`,
        defaultMessage: 13,
      },
      text: {
        id: `${scope}.StatExperienceText`,
        defaultMessage: "Jahre erfahrung",
      },
    },
    coffee: {
      value: {
        id: `${scope}.StatCoffeeValue`,
        defaultMessage: 4,
      },
      text: {
        id: `${scope}.Stat.CoffeeText`,
        defaultMessage: "Liter Kaffee am Tag",
      },
    },
  },
  partner: {
    title: {
      id: `${scope}.PartnerTitle`,
      defaultMessage: "Starke Partner",
    },
  },
  footer: {
    footerStart: {
      id: `${scope}.FooterStartText`,
      defaultMessage: "Auftrag Starten",
    },
    footerStatus: {
      id: `${scope}.FooterStatusText`,
      defaultMessage: "Reparaturstatus",
    },
    footerText: {
      id: `${scope}.FooterText`,
      defaultMessage: " TMC-TEC is eine Marke der   ",
    },
  },
})

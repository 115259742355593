import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby-plugin-react-intl"

import Logo from "../../../logos/tmc-white-orange.svg"

const Container = styled.div`
  font-family: "Open Sans";
  height: 50px;
  background: #333333;
  width: 100%;
  padding: 5px;
  @media (min-width: 1024px) {
    display: grid;
    place-items: center;
  }
  @media (max-width: 1023px) {
    display: none;
  }
`

const Header = styled.div`
  font-size: 14px;
  line-height: 40px;
  font-weight: 100;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`

const HeaderLink = styled(Link)`
  font-family: "Open Sans";
  color: white;
  font-size: 14px;
  text-decoration: none;
  display: block;

  &:hover {
    color: #f06927;
  }
`
const LogoHolder = styled.div`
  width: 100px;
  height: auto;
  display: grid;
  place-items: center;
`
const Button = styled.button`
  font-family: "Open Sans";
  text-decoration: none;
  outline: none;
  border: none;
  position: relative;
  background: transparent;
  &::active {
    text-decoration: none;
    font-family: "Open Sans";
    color: red;
  }
  color: white;
  &:hover {
    color: #f06927;
  }
`

const RepairMenu = styled.div`
  width: 12%;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-contents: center;
  padding: 15px;
  height: 110px;
  background: #333333;
  position: absolute;
  @media (min-width: 1024px) {
    width: 9%;
    border-radius: 5px;
  }
`

const DesktopMenu = () => {
  const [hover, setHover] = useState(false)
  return (
    <Container>
      <Header>
        <HeaderLink to="/">
          <LogoHolder>
            <img src={Logo} alt="logo" width="100px" />
          </LogoHolder>
        </HeaderLink>
        <div>
          <Button onClick={() => setHover(!hover)}>Reparaturservice</Button>
          {hover && (
            <RepairMenu>
              <HeaderLink to="/notebook">Notebook Reparatur</HeaderLink>
              <HeaderLink to="/macbook">Macbook Reparatur</HeaderLink>
              <HeaderLink to="/board">Mainboard Reparatur</HeaderLink>
            </RepairMenu>
          )}
        </div>
        <HeaderLink to="/abwicklung-versand">Abwicklung & Versand</HeaderLink>
        <HeaderLink to="/ueber-uns">Über uns</HeaderLink>
        <HeaderLink to="/kontakt">Kontakt</HeaderLink>
        <HeaderLink to="/reparatur">Reparatur Auftrag</HeaderLink>
        {/* <HeaderLink to="/about">News</HeaderLink> */}
      </Header>
    </Container>
  )
}

export default DesktopMenu

import styled from "styled-components";
import { Link } from "gatsby-plugin-react-intl";

export const FooterMainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background: black;
  color: white;
  font-size: 0.8rem;
  border-top: 1px solid white;
  font-family: "Roboto";
`;

export const FooterSocialMedia = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 10rem;
  width: 100%;
  padding: 0.5rem;
  margin: 1rem 0;
  align-items: center;
`;

export const SocialLink = styled.a`
  text-decoration: none;
  color: white;
  &:hover {
    color: white;
    @media (min-width: 768px) {
      transform: scale(1.2);
    }
  }
`;

export const FooterSoldaText = styled.div`
  margin: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const HeaderLink = styled(Link)`
  font-family: "Open Sans";
  color: #a1a1a6;
  text-decoration: none;
  display: block;

  &:hover {
    color: #f06927;
  }
`;

export const DesktopFooter = styled.div`
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
`;

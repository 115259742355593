import styled from "styled-components"

export const CustomButton = styled.button`
  font-family: "Open Sans";
  font-weight: 300;
  margin: 0 0 0.3rem 0;
  border-radius: 0.2rem;
  font-size: ${props => (props.size === "big" ? "2rem" : "1rem")};
  border: ${props =>
    props.border ? `1px solid ${props => props.color}` : "none"};
  color: ${props => props.color};
  outline: none;
  cursor: pointer;
  background: transparent;
  outline: none;
  &:active {
    border: none;
    outline: none;
  }
`

export const CustomerActionButton = styled(CustomButton)`
  display: flex;
  font-size: 1.5rem;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${props => props.color};
  color: ${props => props.color};
  padding: .1rem 1.8rem;
  text-transform: uppercase;
  &:active {
    border: 1px solid ${props => props.color};
    outline: none;
    margin: 0 0.2rem;

  }
  @media(max-width: 768px){
    font-size: 1rem;
    padding: .2rem .5rem;
  }
`

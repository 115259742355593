import React from "react"
import {
  FaInstagram,
  FaYoutube,
  FaGoogle,
  FaFacebookSquare,
} from "react-icons/fa"
import LogoSolda from "../../logos/solda-logo-white.svg"

import { useIntl } from "gatsby-plugin-react-intl"
import i18n from "../page-components/landing/i18n"

import {
  DesktopFooter,
  FooterMainContainer,
  FooterSocialMedia,
  FooterSoldaText,
  SocialLink,
  HeaderLink,
} from "./styles"

const SOCIAL_MEDIA = [
  {
    link:
      "https://www.facebook.com/TMC-TEC-Notebook-Reparatur-Service-244255418920375",
    icon: <FaFacebookSquare size={30} />,
  },
  {
    link: "https://www.instagram.com/tmc_tec",
    icon: <FaInstagram size={30} />,
  },
  {
    link: "https://youtu.be/4KdpfqYE8Ko",
    icon: <FaYoutube size={30} />,
  },
  {
    link: "https://goo.gl/maps/8g6R5WDKbbHkXKG27",
    icon: <FaGoogle size={30} />,
  },
]

const Footer = () => {
  const intl = useIntl()
  return (
    <FooterMainContainer>
      <FooterSocialMedia>
        {SOCIAL_MEDIA.map((socialMedia, key) => (
          <SocialLink
            key={key}
            href={socialMedia.link}
            rel="noopener noreferrer"
            target="_blank"
          >
            {socialMedia.icon}
          </SocialLink>
        ))}
      </FooterSocialMedia>
      <FooterSoldaText>
        <div style={{ fontFamily: "Open sans" }}>
          &copy; {new Date().getFullYear()}
          {intl.formatMessage(i18n.footer.footerText)}
        </div>
        <div style={{ userSelect: "none" }}>
          <img
            src={LogoSolda}
            width={150}
            style={{ marginBottom: 0, marginLeft: 5 }}
            alt="Solda"
          />
        </div>
      </FooterSoldaText>
      <DesktopFooter>
        <HeaderLink to="/agb">AGB</HeaderLink> |
        <HeaderLink to="/impressum">Impressum</HeaderLink> |
        <HeaderLink to="/datenschutz">Datenschutz</HeaderLink>
      </DesktopFooter>
    </FooterMainContainer>
  )
}

export default Footer

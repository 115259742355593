import styled from "styled-components";
import { Link } from "gatsby-plugin-react-intl";

export const HeaderContainer = styled.div`
  background: #fffffff7;
  z-index: 999;
  width: 100%;
  height: ${(props) => props.height};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #ccc;
  font-size: 2rem;
  user-select: none;
  transition: height 1s;
  @media (min-width: 1024px) {
    display: none;
  }
  @media (max-width: 1023px) {
    display: block;
  }
`;

export const HeaderContent = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;

  animation: slide;
  animation-duration: 8s;
`;

export const HeaderDivision = styled.div`
  flex: ${(props) => props.flex};
  display: flex;
  justify-content: ${(props) => (props.justify ? props.justify : "center")};
`;

export const HeaderLink = styled(Link)`
  color: black;
  text-decoration: none;
`;

export const ModalClose = styled.button`
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  margin: 1rem 0 -1rem 11rem;
`;

import styled from "styled-components"

export const CustomerActionContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 100vw;
  padding: 1.5rem 0.5rem;
  height: 6rem;
  @media (max-width: 768px) {
    height: 1.5rem;
  }
`

export const Img = styled.img`
  height: 50px;
  width: auto;
  margin-right: 2rem;
  @media (max-width: 768px) {
    margin-right: 0.2rem;
    display: none;
  }
`

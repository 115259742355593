import { defineMessages } from "react-intl";

const scope = "title";

export default defineMessages({
  landing: {
    id: `${scope}.landing`,
    defaultMessage: "Willkommen",
  },
  page404: {
    id: `${scope}.page404`,
    defaultMessage: "404 - Seite nicht gefunden",
  },
  notebook: {
    id: `${scope}.notebook`,
    defaultMessage: "Notebook Reparatur",
  },
  macbook: {
    id: `${scope}.macbook`,
    defaultMessage: "Macbook Reparatur",
  },
  board: {
    id: `${scope}.board`,
    defaultMessage: "Mainboardreparatur",
  },
  aboutUs: {
    id: `${scope}.aboutUs`,
    defaultMessage: "Mehr über uns",
  },
  contactUs: {
    id: `${scope}.contactUs`,
    defaultMessage: "Kontakt",
  },
  repair: {
    id: `${scope}.repair`,
    defaultMessage: "Reparatur Auftrag",
  },
  repairStatus: {
    id: `${scope}.repairStatus`,
    defaultMessage: "Reparatur Status",
  },
  imprint: {
    id: `${scope}.imprint`,
    defaultMessage: "Impressum",
  },
  privacy: {
    id: `${scope}.privacy`,
    defaultMessage: "Datenschutz",
  },
  return: {
    id: `${scope}.return`,
    defaultMessage: "Wiederruf",
  },
  agb: {
    id: `${scope}.agb`,
    defaultMessage: "AGB",
  },
});

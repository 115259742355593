import React, { useState } from "react";
import { Sling as Hamburger } from "hamburger-react";
import Modal from "react-modal";
import {
  HeaderContainer,
  HeaderContent,
  HeaderDivision,
  HeaderLink,
  ModalClose,
} from "./styles";
import LogoTmc from "../../logos/tmc-black-orange.svg";
import MainMenu from "./menu";
import {
  ContactActionContainer,
  ContactActionText,
} from "./contact-actions/styles";
import { FaWhatsapp } from "react-icons/fa";

import { MdClose } from "react-icons/md";
import HeaderModal from "./modal";
import DesktopMenu from "./menu/desktopMenu";

Modal.setAppElement("#___gatsby");

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [modal, setModal] = useState(false);

  const ToggleModal = () => {
    if (menuOpen === true) {
      setMenuOpen(false);
      return setModal(true);
    } else {
      setModal(!modal);
    }
  };

  const handleClick = () => {
    return [setMenuOpen(false), setModal(false)];
  };

  return (
    <header>
      <DesktopMenu />
      <HeaderContainer height={!menuOpen ? "64px" : "100vh"}>
        <HeaderContent>
          <HeaderDivision flex={0.25} justify="start">
            <div style={{ textAlign: "center" }}>
              <Hamburger
                size={27}
                onToggle={(toggled) => {
                  if (toggled) {
                    setModal(false);
                    return menuOpen;
                  } else {
                    return !menuOpen;
                  }
                }}
                toggled={menuOpen}
                toggle={setMenuOpen}
              />
            </div>
          </HeaderDivision>
          <HeaderDivision flex={0.5}>
            <HeaderLink to={"/"} onClick={handleClick}>
              <img src={LogoTmc} width={120} height={45} alt="TMC" />
            </HeaderLink>
          </HeaderDivision>
          <HeaderDivision flex={0.25}>
            <ContactActionContainer onClick={ToggleModal}>
              <FaWhatsapp size={"1.4rem"} />
              <ContactActionText>Anruf / Chat</ContactActionText>
              <Modal
                isOpen={modal}
                style={{
                  content: {
                    margin: "120px 0 0 0",
                    height: "300px",
                    overflow: "hidden",
                  },
                }}
              >
                <ModalClose onClick={() => setModal(false)}>
                  <MdClose size={30} />
                </ModalClose>
                <HeaderModal />
              </Modal>
            </ContactActionContainer>
          </HeaderDivision>
        </HeaderContent>

        <HeaderContent>
          <MainMenu />
        </HeaderContent>
      </HeaderContainer>
    </header>
  );
};

export default Header;

import styled from "styled-components";

export const ContactActionContainer = styled.button`
  cursor: pointer;
  width: 100%;
  height: ${(props) => props.height};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  background: none;
  outline: none;
  border: none;
`;

export const ContactActionText = styled.div`
  font-size: 0.7rem;
  font-family: "Open Sans";
  @media (max-width: 370px) {
    font-size: 0.5rem;
  }
  @media (max-width: 320px) {
    font-size: 0.2rem;
  }
`;

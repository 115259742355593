import styled, { createGlobalStyle } from "styled-components"
import { normalize } from "styled-normalize"
import "@fontsource/open-sans"
import "@fontsource/open-sans/300.css"

export const GlobalStyle = createGlobalStyle`
  ${normalize}
:root{
  font-family: "Open Sans", "sans-serif";
}
 
 html,body {
   font-family: "Open Sans", "sans-serif";
     overflow-x: hidden;  
         box-sizing: border-box;
 }
p {
  line-height: 1.5;
}

h1,h2,h3,h4,h5,h6 {
  font-weight: 300;
  margin: 0rem  0 1.5rem 0 ;
}

fieldset {
  margin: 1rem auto;
}




  }
`

export const IconProviderStyle = {
  style: {
    verticalAlign: "middle",
  },
}

export const LayoutContainer = styled.div`
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`

export const StickyContainer = styled.div`
  z-index: 999;
  @media (min-width: 768px) {
    display: ${props => (props.desktop ? "block" : "none")};
  }
`

export const CustomerActionBar = styled.div`
  background-color: #333;
  min-height: 2rem;
  color: white;
`

import React from "react"
import { MainContainer, MainContent } from "./styles"

const Main = ({ children }) => {
  return(
    <MainContainer>
      <MainContent>
        {children}
      </MainContent>
    </MainContainer>
  )
}

export default Main;
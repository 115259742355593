import React from "react"
import Layout from "../../layout"
import Seo from "../../seo"

import { useIntl } from "gatsby-plugin-react-intl"
import i18n from "./i18n"

const Page = ({ title, children }) => {
  const intl = useIntl()

  return (
    <Layout>
      <Seo title={intl.formatMessage(i18n[title])} />
      {children}
    </Layout>
  )
}

export default Page

import React from "react"
import styled from "styled-components"
import { Link } from "gatsby-plugin-react-intl"

const Container = styled.div`
  font-family: "Open Sans";
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: space-between;
`

const Header = styled.div`
  font-size: 20px;
  line-height: 40px;
  font-weight: 100;
  width: 80%;
  margin: 0 auto;
  text-align: left;
`
const Title = styled.h3`
  margin: 1rem auto;
  letter-spacing: 0.1rem;
`

const Footer = styled.div`
  width: 80%;
  font-size: 15px;
  font-weight: 100;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
`

const Border = styled.div`
  width: 80%;
  border-bottom: 1px solid #f06927;
  margin: 2rem auto;
`

const HeaderLink = styled(Link)`
  color: black;
  text-decoration: none;
  display: block;

  &:hover {
    color: #f06927;
  }
`

const MainMenu = () => {
  return (
    <Container>
      <Header>
        <Title>Menü</Title>
        <HeaderLink to="/notebook">Notebook Reparatur</HeaderLink>
        <HeaderLink to="/macbook">Macbook Reparatur</HeaderLink>
        <HeaderLink to="/board">Mainboard Reparatur</HeaderLink>
        <HeaderLink to="/abwicklung-versand">Abwicklung & Versand</HeaderLink>
        <HeaderLink to="/reparatur">Reparatur Auftrag</HeaderLink>
      </Header>
      <Border />
      <Header>
        <HeaderLink to="/ueber-uns">Über uns</HeaderLink>
        <HeaderLink to="/kontakt">Kontakt</HeaderLink>
      </Header>
      <Border />
      <Footer>
        <HeaderLink to="/agb">AGB</HeaderLink>
        <HeaderLink to="/impressum">Impressum</HeaderLink>
        <HeaderLink to="/datenschutz">Datenschutz</HeaderLink>
      </Footer>
    </Container>
  )
}

export default MainMenu

import React from "react"
import { CustomerActionContainer, Img } from "./styles"
import { CustomerActionButton } from "../../common/shared-components/buttons"

import { useIntl } from "gatsby-plugin-react-intl"
import i18n from "../../../components/page-components/landing/i18n"
import STATUS from "../../../logos/status.gif"
import AUFTRAG from "../../../logos/auftrag.gif"
import { HeaderLink } from "../styles"

const CustomerActions = ({ color }) => {
  const intl = useIntl()
  return (
    <CustomerActionContainer>
      <HeaderLink to="/reparatur">
        <CustomerActionButton color={"white"}>
          <Img
            src={AUFTRAG}
            alt="AUFTRAG"
            style={{ padding: " .3rem .1rem" }}
          />{" "}
          {intl.formatMessage(i18n.footer.footerStart)}
        </CustomerActionButton>
      </HeaderLink>
      <HeaderLink to="/status">
        <CustomerActionButton color={"white"}>
          <Img src={STATUS} alt="Status" style={{ padding: ".3rem  .1rem" }} />
          {intl.formatMessage(i18n.footer.footerStatus)}
        </CustomerActionButton>
      </HeaderLink>
    </CustomerActionContainer>
  )
}

export default CustomerActions
